
import { computed, defineComponent, h, resolveComponent, VNode } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import PlusSign from '@/components/atoms/PlusSign'
import Spinner from '@/components/atoms/Spinner'

export default defineComponent({
  name: 'Button',
  components: {
    PlusSign,
    Spinner,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    showPlusSign: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object as () => { src: string; alt: string; class: string },
      default: () => ({
        src: '',
        alt: '',
        class: '',
      }),
    },
    bgColor: {
      type: String as () =>
        | 'green'
        | 'grey'
        | 'lighter-grey'
        | 'black'
        | 'transparent'
        | 'custom',
      default: 'custom',
    },
    anchorTo: {
      type: Object as () => RouteLocationRaw,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const bgColor = computed(() => {
      const darkBorder = 'border border-grey-fp-3 '
      const bgColors = {
        green: {
          base: 'bg-green-fp-2',
          hover: 'hover:bg-green-fp-3',
        },
        grey: {
          base: darkBorder + 'bg-grey-fp-1',
          hover: 'hover:bg-grey-fp-3',
        },
        'lighter-grey': {
          base: 'bg-grey-fp-2',
          hover: 'hover:bg-grey-fp-3',
        },
        black: {
          base: darkBorder + 'bg-black',
          hover: 'hover:bg-grey-fp-3',
        },
        transparent: {
          base: 'bg-transparent border border-grey-fp-3',
          hover: 'hover:bg-grey-fp-3',
        },
        custom: {
          base: '',
          hover: '',
        },
      }
      return props.isLoading
        ? bgColors[props.bgColor].base
        : `${bgColors[props.bgColor].base} ${bgColors[props.bgColor].hover}`
    })

    const getChildren = computed(() => {
      const children: VNode[] = []
      if (props.isLoading) {
        children.push(
          h(resolveComponent('Spinner'), {
            class: 'absolute inset-0',
          })
        )
      } else {
        if (props.text)
          children.push(
            h(
              'p',
              {
                class: [
                  props.showPlusSign ? 'mr-3' : '',
                  'font-bold text-base',
                ],
              },
              props.text
            )
          )

        if (props.icon.src)
          children.push(
            h('img', {
              src: props.icon.src,
              alt: props.icon.alt,
              class: [props.text ? 'ml-3' : '', props.icon.class],
            })
          )

        if (props.showPlusSign) children.push(h(PlusSign))
      }

      return children
    })

    const getBaseElement = () => {
      if (props.anchorTo) return resolveComponent('router-link') as any
      return 'button'
    }

    return () =>
      h(
        getBaseElement(),
        {
          class: [
            'button relative',
            props.text ? 'w-40' : 'w-16',
            bgColor.value,
            props.isDisabled ? 'opacity-50 pointer-events-none' : '',
          ],
          ...(props.anchorTo && { to: props.anchorTo }),
        },
        {
          // The default slot
          default() {
            return getChildren.value
          },
        }
      )
  },
})
