
import { defineComponent, reactive, ref } from 'vue'
import { useRouter, RouteLocationRaw } from 'vue-router'

import { onSubmitForm } from './types'

import Button from '@/components/atoms/Button'

export default defineComponent({
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    confirmButtonTitle: {
      type: String,
      required: false,
    },
    cancelButtonTitle: {
      type: String,
      default: 'Back',
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    disableSubmitBtn: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Object as () => RouteLocationRaw,
      required: false,
    },
    showFpLogo: {
      type: Boolean,
      default: true,
    },
    dualColumnLayout: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter()
    const isLoading = ref(false)
    const message = reactive({ text: '', success: false })

    const baseOnSubmit = async () => {
      isLoading.value = true
      const submit: ReturnType<onSubmitForm> = props.onSubmit()

      submit.then(({ text, success }) => {
        message.text = text
        message.success = success
        if (success && props.redirect) {
          router.push(props.redirect)
        } else {
          isLoading.value = false
        }
      })
    }

    return {
      isLoading,
      baseOnSubmit,
      message,
    }
  },
})
