import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.baseOnSubmit && _ctx.baseOnSubmit(...args)), ["prevent"])),
    class: "flex flex-col"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["grid gap-8", _ctx.dualColumnLayout ? 'grid-cols-2' : ''])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", {
        class: _normalizeClass(["flex w-full mt-1", _ctx.dualColumnLayout ? 'col-span-2 justify-end' : ''])
      }, [
        _createVNode(_component_Button, {
          type: "button",
          text: _ctx.cancelButtonTitle,
          bgColor: "transparent",
          isDisabled: _ctx.isLoading,
          class: _normalizeClass(["mr-4", _ctx.dualColumnLayout ? '' : 'w-full']),
          onClick: _ctx.$goBack
        }, null, 8, ["text", "isDisabled", "class", "onClick"]),
        _createVNode(_component_Button, {
          type: "submit",
          text: _ctx.confirmButtonTitle ? _ctx.confirmButtonTitle : _ctx.title,
          bgColor: "green",
          isDisabled: _ctx.isLoading || _ctx.disableSubmitBtn,
          isLoading: _ctx.isLoading,
          class: _normalizeClass(_ctx.dualColumnLayout ? '' : 'w-full')
        }, null, 8, ["text", "isDisabled", "isLoading", "class"])
      ], 2),
      _withDirectives(_createElementVNode("p", {
        class: _normalizeClass([_ctx.message.success ? 'text-green-fp-2' : 'text-warn-strong'])
      }, _toDisplayString(_ctx.message.text), 3), [
        [_vShow, _ctx.message.text]
      ])
    ], 2),
    _renderSlot(_ctx.$slots, "after-submit-button")
  ], 32))
}