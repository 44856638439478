
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import { realmApp } from '@/helpers'
import { onSubmitForm } from '@/components/molecules/Form/types'

import Input from '@/components/atoms/Input'
import HalfPageHalfImage from '@/components/atoms/HalfPageHalfImage'
import Terms from '@/components/atoms/Terms'
import Form from '@/components/molecules/Form'

export default defineComponent({
  components: {
    Input,
    HalfPageHalfImage,
    Terms,
    Form,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      password1: '',
      password2: '',
      errorMessages: {
        password1: '',
        password2: '',
      },
      success: false,
    })

    const resetPassword: onSubmitForm = async () => {
      // Provided by Realm's password reset email link
      const { token, tokenId } = route.query as Record<string, string>

      if (!validateInput()) return { text: '', success: false }
      if (!token || !tokenId) return { text: 'Missing tokens', success: false }

      try {
        await realmApp.emailPasswordAuth.resetPassword(
          token,
          tokenId,
          state.password1
        )
        state.success = true
        return { text: 'Success', success: true }
      } catch (error) {
        console.error(error)
        return {
          text: (error as { error: string }).error ?? 'An error occurred',
          success: false,
        }
      }
    }

    const validateInput = () => {
      state.errorMessages.password1 = ''
      state.errorMessages.password2 = ''

      if (!state.password1)
        state.errorMessages.password1 = 'Please enter a new password.'
      if (!state.password2)
        state.errorMessages.password2 = 'Please confirm your password.'
      else if (state.password1 !== state.password2)
        state.errorMessages.password2 = 'Your passwords do not match.'

      return !!(
        state.password1 &&
        state.password2 &&
        state.password1 === state.password2
      )
    }

    return {
      resetPassword,
      ...toRefs(state),
    }
  },
})
